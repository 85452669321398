import React, { useEffect, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import "../styles/HomePage.css";

const HomePage = () => {
    const [baustellen, setBaustellen] = useState([]);
    const { setSelectedBaustelle } = useOutletContext();

    useEffect(() => {
        const fetchBaustellen = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/baustellen`);
                const data = await response.json();
                setBaustellen(data);
            } catch (error) {
                console.error("Błąd podczas pobierania budów:", error);
            }
        };

        fetchBaustellen();
    }, []);

    const handleSelectBaustelle = (baustelle) => {
        setSelectedBaustelle(baustelle);
    };

    return (
        <div className="homepage-container">
            <h1 className="title">Baustellen</h1>
            <div className="data-card">
                <div className="data-section">
                    <h2>Data</h2>
                    <div className="data-item">
                        <span>18 Unternehmen</span>
                        <span className="data-status">Alle geprüft</span>
                    </div>
                    <div className="data-item">
                        <span>109 Mitarbeiter</span>
                        <span className="data-highlight">4 zu prüfen</span>
                    </div>
                </div>
                <div className="circle-chart">
                    <p>0 / 109</p>
                </div>
            </div>
            <div className="tile-container">
                {baustellen.map((baustelle) => (
                    <Link
                        to={`/start/${baustelle.id_bv}`}
                        key={baustelle.id_bv}
                        className="tile-link"
                        onClick={() => handleSelectBaustelle(baustelle)}
                    >
                        <div className="tile">
                            <h3>{baustelle.bv_name}</h3>
                            <p>{baustelle.bv_strasse}</p>
                            <p>
                                {baustelle.bv_plz} {baustelle.bv_stadt}
                            </p>
                            <p>{baustelle.bv_land}</p>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default HomePage;
