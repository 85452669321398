import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const Start = () => {
    const { id } = useParams(); // Pobieranie ID budowy z URL
    const [baustelle, setBaustelle] = useState(null);

    useEffect(() => {
        const fetchBaustelleDetails = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/baustellen/${id}`);
                if (!response.ok) {
                    throw new Error("Nie udało się pobrać szczegółów budowy.");
                }
                const data = await response.json();
                setBaustelle(data);
            } catch (error) {
                console.error("Błąd podczas pobierania szczegółów budowy:", error);
            }
        };

        fetchBaustelleDetails();
    }, [id]);

    if (!baustelle) {
        return <div>Ładowanie szczegółów budowy...</div>;
    }

    return (
        <div>
            <h1>Strona główna budowy</h1>
            <h2>{baustelle.bv_name}</h2>
            <p><strong>Adres:</strong> {baustelle.bv_strasse}, {baustelle.bv_plz} {baustelle.bv_stadt}</p>
            <p><strong>Kraj:</strong> {baustelle.bv_land}</p>
            {/* Możesz dodać więcej szczegółów budowy tutaj */}
        </div>
    );
};

export default Start;
