import React, { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import MenuAdmin from "../Menu/MenuAdmin";
import MenuWachman from "../Menu/MenuWachman";
import MenuBaucher from "../Menu/MenuBaucher";
import MenuFirma from "../Menu/MenuFirma";
import { UserContext } from "../../context/UserContext";

const MainLayout = () => {
  const { user, handleLogout } = useContext(UserContext);
  const [selectedBaustelle, setSelectedBaustelle] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      console.log("Nie znaleziono użytkownika, przekierowanie na login...");
      navigate("/login");
    }
  }, [user, navigate]);

  const renderMenuForRole = () => {
    switch (user.role) {
      case "Admin":
        return <MenuAdmin user={user} onLogout={handleLogout} />;
      case "Wachman":
        return <MenuWachman user={user} onLogout={handleLogout} />;
      case "Baucher":
        return <MenuBaucher user={user} onLogout={handleLogout} />;
      case "Firma":
        return <MenuFirma user={user} onLogout={handleLogout} />;
      default:
        return null;
    }
  };

  return (
      <div>
        {selectedBaustelle ? (
            <>
              {renderMenuForRole()}
              <div style={{ marginTop: "70px" }}>
                <Outlet context={{ selectedBaustelle, setSelectedBaustelle }} />
              </div>
            </>
        ) : (
            <div>
              <h2>Proszę wybrać budowę</h2>
              <Outlet context={{ setSelectedBaustelle }} />
            </div>
        )}
      </div>
  );
};

export default MainLayout;