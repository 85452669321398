import React, { useState, useEffect } from 'react';
import '../styles/CompaniesList.css'; // Import stylów

const CompaniesList = () => {
  const [companies, setCompanies] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingId, setLoadingId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Aktualna strona
  const [recordsPerPage, setRecordsPerPage] = useState(10); // Rekordy na stronę
  const [totalPages, setTotalPages] = useState(0); // Całkowita liczba stron
  const [totalRecords, setTotalRecords] = useState(0); // Całkowita liczba rekordów
  const API_URL = process.env.REACT_APP_API_URL || 'http://avisierung.de';

  // Funkcja do pobierania danych
  const fetchCompanies = async () => {
    setIsLoading(true);
    setError(null);
    try {
      console.log('Ładowanie danych z API...');
      const response = await fetch(
          `${API_URL}/companies?page=${currentPage}&limit=${recordsPerPage}`
      );

      if (!response.ok) {
        throw new Error(`Błąd sieci: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Dane pobrane z API:', data); // Logowanie danych

      // Logowanie filtrowanych danych (jeśli dotyczy)
      console.log(
          'Filtrowane dane (tylko aktywne firmy):',
          data.data.filter((firma) => firma.aktiv === 1)
      );

      // Ustawianie stanów
      setCompanies(data.data || []); // Ustawienie rekordów
      setTotalPages(data.totalPages || 0); // Ustawienie całkowitej liczby stron
      setTotalRecords(data.totalRecords || 0); // Ustawienie całkowitej liczby rekordów
    } catch (error) {
      console.error('Błąd podczas pobierania firm:', error);
      setError('Nie udało się załadować danych.');
    } finally {
      setIsLoading(false);
    }
  };

  // Hook useEffect - pobranie danych podczas ładowania komponentu lub zmiany strony/liczby rekordów
  useEffect(() => {
    fetchCompanies();
  }, [currentPage, recordsPerPage]);

  // Funkcja do aktywacji firmy
  const handleActivate = async (id) => {
    try {
      console.log(`Aktywacja firmy o ID: ${id}`);
      setLoadingId(id);

      const response = await fetch(`${API_URL}/activate-company/${id}`, {
        method: 'POST',
      });

      if (!response.ok) {
        throw new Error('Nie udało się aktywować firmy.');
      }

      const data = await response.json();
      console.log('Wynik aktywacji:', data.message);

      setCompanies((prevCompanies) =>
          prevCompanies.map((firma) =>
              firma.id === id ? { ...firma, aktiv: true } : firma
          )
      );
    } catch (error) {
      console.error('Błąd podczas aktywacji firmy:', error);
      setError('Nie udało się aktywować firmy.');
    } finally {
      setLoadingId(null);
    }
  };

  // Funkcja obsługująca zmianę strony
  const changePage = (pageNumber) => {
    console.log(`Zmiana strony na: ${pageNumber}`);
    setCurrentPage(pageNumber);
  };

  // Funkcja obsługująca zmianę liczby rekordów na stronę
  const handleRecordsPerPageChange = (e) => {
    const newLimit = Number(e.target.value);
    console.log(`Zmiana liczby rekordów na stronę na: ${newLimit}`);
    setRecordsPerPage(newLimit);
    setCurrentPage(1); // Powrót na pierwszą stronę po zmianie
  };

  // Wyświetlenie tabeli lub odpowiednich komunikatów
  return (
      <div className="table-container">
        {isLoading ? (
            <p style={{ textAlign: 'center' }}>Przetwarzanie... Proszę czekać.</p>
        ) : error ? (
            <p style={{ textAlign: 'center', color: 'red' }}>{error}</p>
        ) : companies.length > 0 ? (
            <>
              <table>
                <thead>
                <tr>
                  <th>Status</th>
                  <th>Genehmigt</th>
                  <th>Name</th>
                  <th>Adresse</th>
                  <th>PLZ</th>
                  <th>Ort</th>
                  <th>Land</th>
                  <th>Telefon</th>
                  <th>E-Mail</th>
                  <th>Akcja</th>
                </tr>
                </thead>
                <tbody>
                {companies.map((firma) => {
                  console.log('Wyświetlana firma w tabeli:', firma); // Logowanie szczegółów firmy
                  return (
                      <tr key={firma.id}>
                        <td>{firma.aktiv ? '✔️' : '❌'}</td>
                        <td>{firma.g ? '✔️' : '❌'}</td>
                        <td>{firma.name}</td>
                        <td>{firma.strasse}</td>
                        <td>{firma.plz}</td>
                        <td>{firma.ort}</td>
                        <td>{firma.land}</td>
                        <td>{firma.telefon}</td>
                        <td>{firma.email}</td>
                        <td>
                          {!firma.aktiv ? (
                              loadingId === firma.id ? (
                                  <span>Ładowanie...</span>
                              ) : (
                                  <button onClick={() => handleActivate(firma.id)}>
                                    Aktivieren
                                  </button>
                              )
                          ) : (
                              <span>Aktywny</span>
                          )}
                        </td>
                      </tr>
                  );
                })}
                </tbody>
              </table>

              {/* Paginacja */}
              <div className="pagination-container">
                <div className="records-per-page">
                  <label htmlFor="recordsPerPage">Rekordy na stronę:</label>
                  <select
                      id="recordsPerPage"
                      value={recordsPerPage}
                      onChange={handleRecordsPerPageChange}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                  </select>
                </div>
                <div className="pagination">
                  {Array.from({ length: totalPages }, (_, index) => index + 1).map((number) => (
                      <button
                          key={number}
                          onClick={() => changePage(number)}
                          className={number === currentPage ? 'active' : ''}
                      >
                        {number}
                      </button>
                  ))}
                </div>
              </div>
            </>
        ) : (
            <p style={{ textAlign: 'center' }}>Brak danych do wyświetlenia.</p>
        )}
      </div>
  );
};

export default CompaniesList;
