import { createBrowserRouter } from "react-router-dom";
import MainLayout from "../components/Layout/MainLayout";
import HomePage from "../pages/HomePage";
import UnternehmenOption1 from "../pages/UnternehmenOption1";
import UnternehmenOption2 from "../pages/UnternehmenOption2";
import CompaniesList from "../pages/CompaniesList";
import CompaniesBauher from "../pages/CompaniesBauher";
import Mitarbeiter from "../pages/Mitarbeiter";
import Baustellenausweise from "../pages/Baustellenausweise";
import Prufung from "../pages/Prufung";
import Prufung2 from "../pages/Prufung2";
import Lesegerat from "../pages/Lesegerat";
import Einladungen from "../pages/Einladungen";
import Anweisenlog from "../pages/Anweisenlog";
import Anweisenliste from "../pages/Anweisenliste";
import Reports from "../pages/Reports";
import Login from "../pages/Login";
import CompanyDetails from "../pages/CompanyDetails";
import AddMitarbeiter from "../pages/AddMitarbeiter";
import Start from "../pages/Start";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "/", element: <HomePage /> },
      { path: "/start/:id", element: <Start /> },
      { path: "/unternehmen-1", element: <UnternehmenOption1 /> },
      { path: "/unternehmen-2", element: <UnternehmenOption2 /> },
      { path: "/unternehmen-3", element: <CompaniesList /> },
      { path: "/unternehmen-4", element: <CompaniesBauher /> },
      { path: "/companies-details/:id", element: <CompanyDetails /> },
      { path: "/addmitarbeiter", element: <AddMitarbeiter /> },

      { path: "/mitarbeiter", element: <Mitarbeiter /> },
      { path: "/baustellenausweise", element: <Baustellenausweise /> },
      { path: "/prufung", element: <Prufung /> },
      { path: "/prufung-2", element: <Prufung2 /> },
      { path: "/lesegerat", element: <Lesegerat /> },
      { path: "/einladungen", element: <Einladungen /> },
      { path: "/anwesenheitslog", element: <Anweisenlog /> },
      { path: "/anwesenheitsliste", element: <Anweisenliste /> },
      { path: "/reports", element: <Reports /> },
    ],
  },
  { path: "/login", element: <Login /> },
]);

export default router;
