import React, { useState } from 'react';
import '../styles/Login.css';

const API_URL = process.env.REACT_APP_API_URL || 'https://avisierung.de';

const Login = ({ onLogin }) => {
    const [firstName, setFirstName] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Rozpoczęto logowanie...');
    console.log('Wprowadzone dane:', { firstName, password });

    try {
        const response = await fetch(`${API_URL}/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ firstName, password }),
        });

        console.log('Otrzymano odpowiedź od serwera:', response);

        const data = await response.json();
        console.log('Dane z odpowiedzi serwera:', data);

        if (response.ok) {
            console.log('Logowanie powiodło się. Dane użytkownika:', data.user);
            onLogin(data.user); // Przekazuj tylko obiekt `user`
        } else {
            console.error('Błąd logowania:', data.message || 'Nieznany błąd');
            setError(data.message || 'Błąd logowania');
        }
    } catch (err) {
        console.error('Błąd połączenia z serwerem:', err);
        setError('Błąd połączenia z serwerem');
    }
};


    return (
        <form className="login-form" onSubmit={handleSubmit}>
            <h1>Anmelden</h1>
            <div className="form-group">
                <label>Vorname:</label>
                <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                />
            </div>
            <div className="form-group">
                <label>Passwort:</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
            </div>
            <button type="submit">Zaloguj</button>
            {error && <p className="error-message">{error}</p>}
        </form>
    );
};

export default Login;
