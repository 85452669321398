import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // Dodano Link

import '../styles/CompaniesList.css'; // Import stylów

const CompaniesBauher = () => {
  const [companies, setCompanies] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1); // Aktualna strona
  const [recordsPerPage, setRecordsPerPage] = useState(10); // Rekordy na stronę
  const [totalPages, setTotalPages] = useState(0); // Całkowita liczba stron
  const [totalRecords, setTotalRecords] = useState(0); // Całkowita liczba rekordów
  const navigate = useNavigate(); // Zaktualizowany sposób nawigacji
  const API_URL = process.env.REACT_APP_API_URL || 'http://avisierung.de';

  // Funkcja do pobierania danych
  const fetchCompanies = async () => {
    setIsLoading(true);
    setError(null);
    try {
      console.log('Ładowanie danych z API...');
      const response = await fetch(`${API_URL}/companies?page=${currentPage}&limit=${recordsPerPage}`);

      if (!response.ok) {
        throw new Error(`Błąd sieci: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();

      // Logowanie odpowiedzi z API
      console.log('Otrzymane dane z API:', data);

      // Logowanie szczegółów paginacji
      console.log('Paginacja:', {
        totalPages: data.totalPages,
        totalRecords: data.totalRecords,
        currentPage,
      });

      // Ustawianie danych
      setCompanies(data.data || []); // Ustaw dane firm
      setTotalPages(data.totalPages || 0); // Ustaw liczbę stron
      setTotalRecords(data.totalRecords || 0); // Ustaw liczbę rekordów
    } catch (error) {
      console.error('Błąd podczas pobierania firm:', error);
      setError('Nie udało się załadować danych.');
    } finally {
      setIsLoading(false);
    }
  };

  // Hook useEffect - pobranie danych podczas ładowania komponentu lub zmiany strony/liczby rekordów
  useEffect(() => {
    fetchCompanies();
  }, [currentPage, recordsPerPage]);

  // Funkcja do nawigacji do strony szczegółów firmy
  const navigateToCompanyDetails = (id) => {
    navigate(`/companies-details/${id}`);
  };

  // Funkcja obsługująca zmianę strony
  const changePage = (pageNumber) => {
    console.log(`Zmiana strony na: ${pageNumber}`);
    setCurrentPage(pageNumber);
  };

  // Funkcja obsługująca zmianę liczby rekordów na stronę
  const handleRecordsPerPageChange = (e) => {
    const newLimit = Number(e.target.value);
    console.log(`Zmiana liczby rekordów na stronę na: ${newLimit}`);
    setRecordsPerPage(newLimit);
    setCurrentPage(1); // Powrót na pierwszą stronę po zmianie
  };





  // Wyświetlenie tabeli lub odpowiednich komunikatów
  return (
      <div className="table-container">
        {isLoading ? (
            <p style={{ textAlign: 'center' }}>Przetwarzanie... Proszę czekać.</p>
        ) : error ? (
            <p style={{ textAlign: 'center', color: 'red' }}>{error}</p>
        ) : companies.length > 0 ? (
            <>
              <table>
                <thead>
                <tr>
                  <th>Status</th>
                  <th>Genehmigt</th>
                  <th>Name</th>
                  <th>Adresse</th>
                  <th>PLZ</th>
                  <th>Ort</th>
                  <th>Land</th>
                  <th>Telefon</th>
                  <th>E-Mail</th>
                </tr>
                </thead>
                <tbody>
                {companies.map((company) => {
                  console.log('Wyświetlana firma:', company); // Logowanie szczegółów firmy
                  return (
                      <tr key={company.id}>
                        <td>
                      <span
                          className={`status-icon ${
                              company.aktiv === 1 ? 'status-true' : 'status-false'
                          }`}
                      >
                        {company.aktiv === 1 ? '✔️' : '❌'}
                      </span>
                        </td>
                        <td>{company.g === 1 ? '✔️' : '❌'}</td>
                        <td><span
                            className="link-button"
                            onClick={() => navigateToCompanyDetails(company.id)}
                        >
        {company.name}
    </span></td>
                        <td>{company.strasse}</td>
                        <td>{company.plz}</td>
                        <td>{company.ort}</td>
                        <td>{company.land}</td>
                        <td>{company.telefon}</td>
                        <td>{company.email}</td>
                      </tr>
                  );
                })}
                </tbody>
              </table>
              {/* Paginacja i obsługa rekordów na stronę */}
              <div className="pagination-container">
                <div className="records-per-page">
                  <label htmlFor="recordsPerPage">Rekordy na stronę:</label>
                  <select
                      id="recordsPerPage"
                      value={recordsPerPage}
                      onChange={handleRecordsPerPageChange}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                  </select>
                </div>
                <div className="pagination">
                  {Array.from({ length: totalPages }, (_, index) => index + 1).map((number) => (
                      <button
                          key={number}
                          onClick={() => changePage(number)}
                          className={number === currentPage ? 'active' : ''}
                      >
                        {number}
                      </button>
                  ))}
                </div>
              </div>
            </>
        ) : (
            <p style={{ textAlign: 'center' }}>Brak danych do wyświetlenia.</p>
        )}
      </div>
  );
};

export default CompaniesBauher;
