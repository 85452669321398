import React, { useState, useEffect } from "react";
import { Navbar, Nav, Dropdown } from "react-bootstrap";
import { useNavigate, NavLink } from "react-router-dom";
import "./MenuAdmin.css";

const MenuAdmin = ({ user, onLogout }) => {
  const navigate = useNavigate(); // Hook do nawigacji w React Router
  const [currentRole, setCurrentRole] = useState(user?.role || "Brak roli");
  const [isMenuExpanded, setIsMenuExpanded] = useState(false); // Stan rozwinięcia menu

  useEffect(() => {
    setCurrentRole(user?.role || "Brak roli");
  }, [user?.role]);

  const handleNavigation = (path) => {
    console.log(`Nawigacja do: ${path}`);
    navigate(path); // Nawigacja na podstawie ścieżki
    setIsMenuExpanded(false); // Zwinięcie menu po nawigacji
  };

  const handleToggleMenu = () => {
    setIsMenuExpanded((prev) => !prev); // Przełączanie rozwinięcia menu
  };

  return (
    <Navbar bg="light" expand="lg" fixed="top" className="sticky-menu" expanded={isMenuExpanded}>
      <div className="container-fluid">
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggleMenu} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavLink
              to="/"
              className="menu-item nav-link"
              onClick={() => setIsMenuExpanded(false)} // Zwijanie menu
            >
              <i className="bi bi-tools"></i> Übersicht
            </NavLink>
            <Dropdown>
              <Dropdown.Toggle variant="light" className="menu-item">
                <i className="bi bi-building"></i> Unternehmen
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleNavigation("/unternehmen-1")}>
                  Option 1
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleNavigation("/unternehmen-2")}>
                  Option 2
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <NavLink
              to="/mitarbeiter"
              className="menu-item nav-link"
              onClick={() => setIsMenuExpanded(false)}
            >
              <i className="bi bi-people"></i> Mitarbeiter
            </NavLink>
            <NavLink
              to="/baustellenausweise"
              className="menu-item nav-link"
              onClick={() => setIsMenuExpanded(false)}
            >
              <i className="bi bi-card-text"></i> Baustellenausweise
            </NavLink>
            <Dropdown>
              <Dropdown.Toggle variant="light" className="menu-item">
                <i className="bi bi-clipboard-check"></i> Prüfungen
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleNavigation("/prufung-1")}>
                  Option 1
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleNavigation("/prufung-2")}>
                  Option 2
                </Dropdown.Item>
 <Dropdown.Item onClick={() => handleNavigation("/unternehmen-3")}>
      Aktivierung des Unternehmens
    </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <NavLink
              to="/lesegerat"
              className="menu-item nav-link"
              onClick={() => setIsMenuExpanded(false)}
            >
              <i className="bi bi-device-hdd"></i> Lesegerät
            </NavLink>
            <NavLink
              to="/einladungen"
              className="menu-item nav-link"
              onClick={() => setIsMenuExpanded(false)}
            >
              <i className="bi bi-envelope"></i> Einladungen
            </NavLink>
            <NavLink
              to="/anwesenheitslog"
              className="menu-item nav-link"
              onClick={() => setIsMenuExpanded(false)}
            >
              <i className="bi bi-calendar"></i> Anwesenheitslog
            </NavLink>
            <NavLink
              to="/anwesenheitsliste"
              className="menu-item nav-link"
              onClick={() => setIsMenuExpanded(false)}
            >
              <i className="bi bi-clipboard-data"></i> Anwesenheitsliste
            </NavLink>
            <NavLink
              to="/reports"
              className="menu-item nav-link"
              onClick={() => setIsMenuExpanded(false)}
            >
              <i className="bi bi-download"></i> Reports
            </NavLink>
          </Nav>
          <Dropdown align="end">
            <Dropdown.Toggle variant="link" className="user-info">
              {user.firstName} {user.lastName} ({currentRole})
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item disabled>Profil</Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  onLogout();
                  setIsMenuExpanded(false); // Zwijanie menu po wylogowaniu
                }}
              >
                Wyloguj
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default MenuAdmin;
