import React, { useState } from 'react';


import '../styles/AddMitarbeiter.css';

const AddMitarbeiter = ({ firmaId }) => {
    const [vorname, setVorname] = useState('');
    const [name, setName] = useState('');
    const [personalausweis, setPersonalausweis] = useState('');
    const [ablaufdatum, setAblaufdatum] = useState('');
    const [staat, setStaat] = useState('');
    const [typdokument, setTypdokument] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('/addmitarbeiter', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    firma_id: firmaId,
                    vorname,
                    name,
                    personalausweis,
                    ablaufdatum,
                    staat,
                    typdokument,
                }),
            });

            const data = await response.json();
            if (response.ok) {
                alert('Pracownik został dodany.');
                // Wyczyść formularz
                setVorname('');
                setName('');
                setPersonalausweis('');
                setAblaufdatum('');
                setStaat('');
                setTypdokument('');
            } else {
                alert(`Błąd: ${data.message}`);
            }
        } catch (error) {
            console.error('Błąd podczas dodawania pracownika:', error);
            alert('Wystąpił błąd podczas dodawania pracownika.');
        }
    };

    return (
        <div className="add-mitarbeiter-container">
            <h2 className="section-title">Dodaj Pracownika</h2>
            <form onSubmit={handleSubmit} className="form-container">
                <div className="form-row">
                    <label className="form-label">Imię:</label>
                    <input
                        className="form-input"
                        value={vorname}
                        onChange={(e) => setVorname(e.target.value)}
                        required
                    />
                </div>
                <div className="form-row">
                    <label className="form-label">Nazwisko:</label>
                    <input
                        className="form-input"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                <div className="form-row">
                    <label className="form-label">Numer dowodu:</label>
                    <input
                        className="form-input"
                        value={personalausweis}
                        onChange={(e) => setPersonalausweis(e.target.value)}
                        required
                    />
                </div>
                <div className="form-row">
                    <label className="form-label">Data wygaśnięcia:</label>
                    <input
                        className="form-input"
                        type="date"
                        value={ablaufdatum}
                        onChange={(e) => setAblaufdatum(e.target.value)}
                        required
                    />
                </div>
                <div className="form-row">
                    <label className="form-label">Państwo:</label>
                    <input
                        className="form-input"
                        value={staat}
                        onChange={(e) => setStaat(e.target.value)}
                        required
                    />
                </div>
                <div className="form-row">
                    <label className="form-label">Typ dokumentu:</label>
                    <input
                        className="form-input"
                        value={typdokument}
                        onChange={(e) => setTypdokument(e.target.value)}
                        required
                    />
                </div>
                <div className="form-actions">
                    <button type="submit" className="form-button">Dodaj pracownika</button>
                </div>
            </form>
        </div>
    );
};

export default AddMitarbeiter;
