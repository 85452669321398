import React, { useState, useEffect } from "react";
import { Navbar, Nav, Dropdown } from "react-bootstrap";
import { useNavigate, NavLink } from "react-router-dom";
import "./MenuWachman.css";

const MenuWachman = ({ user, onLogout }) => {
  const navigate = useNavigate(); // Hook do nawigacji w React Router
  const [currentRole, setCurrentRole] = useState(user?.role || "Brak roli");

  useEffect(() => {
    setCurrentRole(user?.role || "Brak roli");
  }, [user?.role]);

  const handleNavigation = (path) => {
    console.log(`Nawigacja do: ${path}`);
    navigate(path); // Nawigacja na podstawie ścieżki
  };

  return (
    <Navbar bg="light" expand="lg" fixed="top" className="sticky-menu">
      <div className="container-fluid">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavLink to="/" className="menu-item nav-link">
              <i className="bi bi-tools"></i> Übersicht
            </NavLink>
            <Dropdown>
              <Dropdown.Toggle variant="light" className="menu-item">
                <i className="bi bi-building"></i> Unternehmen
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleNavigation("/unternehmen-1")}>
                  Option 1
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleNavigation("/unternehmen-2")}>
                  Option 2
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown>
              <Dropdown.Toggle variant="light" className="menu-item">
                <i className="bi bi-building"></i> Mitarbeiter
              </Dropdown.Toggle>
              <Dropdown.Menu>

                <Dropdown.Item onClick={() => handleNavigation("/addmitarbeiter")}>
                  Mitarbeiter hinzufügen
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <NavLink to="/baustellenausweise" className="menu-item nav-link">
              <i className="bi bi-card-text"></i> Baustellenausweise
            </NavLink>
            <Dropdown>
              <Dropdown.Toggle variant="light" className="menu-item">
                <i className="bi bi-clipboard-check"></i> Prüfungen
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleNavigation("/prufung-1")}>
                  Option 1
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleNavigation("/prufung-2")}>
                  Option 2
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <NavLink to="/lesegerat" className="menu-item nav-link">
              <i className="bi bi-device-hdd"></i> Lesegerät
            </NavLink>
            <NavLink to="/einladungen" className="menu-item nav-link">
              <i className="bi bi-envelope"></i> Einladungen
            </NavLink>
            <NavLink to="/anwesenheitslog" className="menu-item nav-link">
              <i className="bi bi-calendar"></i> Anwesenheitslog
            </NavLink>
            <NavLink to="/anwesenheitsliste" className="menu-item nav-link">
              <i className="bi bi-clipboard-data"></i> Anwesenheitsliste
            </NavLink>
            <NavLink to="/reports" className="menu-item nav-link">
              <i className="bi bi-download"></i> Reports
            </NavLink>
          </Nav>
          <Dropdown align="end">
            <Dropdown.Toggle variant="link" className="user-info">
              {user.firstName} {user.lastName} ({currentRole})
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item disabled>Profil</Dropdown.Item>
              <Dropdown.Item onClick={onLogout}>Abmelden</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default MenuWachman;
